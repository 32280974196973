// TODO consider hiding pricemin/pricemax filter area when these two numbers are the same
const productList4i = (function() {
	'use strict';
 
	const productContainer = document.getElementById('productContainer');
	const mobileFilterDropDown = document.getElementById('filterDropDown');

	const prodListValues = productListValues(document.getElementById('ItemsPerPage').value, document.getElementById('isUserRegistered').value);
	const filterAccordions = new getAccordions4i();
	//const betterChoicesModal = new Dialog4i(); 
	const starRatingJS = new StarRating4i();
	starRatingJS.Init();

	const common = (function() {
		const toggleButtonLabel = function(element) {
			const label = element.innerHTML;
			element.innerHTML = element.getAttribute('data-text');
			element.setAttribute('data-text', label);
		};

		const getUpdatedFilterCount = function(element, count, description) {
			var label = element.innerHTML;
			const index = label.lastIndexOf('(');
			const productLabel = ' products';
			const attribute = 'aria-label';

			if (index) {
				label = label.slice(0, index);
			}

			element.innerHTML = label + '(' + count + ')';

			if (filterValues.IsRatingId(element.id)) {
				element.setAttribute(attribute, description + ' rating ' + count + productLabel);
			} else {
				element.setAttribute(attribute, label + count + productLabel);
			}
		};

		return {
			ToggleButtonLabel: toggleButtonLabel,
			GetUpdatedFilterCount: getUpdatedFilterCount,
		};
	})();

	// manages area that displays which filters have been selected
	const selectedFilters = (function() {
		const clearAllFilters = document.getElementById('clearAllFilters');
		const clearAllFiltersMobile = document.getElementById('clearAllFiltersMobile');
		const list = document.getElementById('selectedFilterList');
		const listMobile = document.getElementById('selectedFilterListMobile');
		const filtersSelected = document.getElementById('filtersSelected');
		const filtersSelectedMobile = document.getElementById('filtersSelectedMobile');
		const viewMore = document.getElementById('ViewMoreSelectedFilters');

		const addToGroup = function(id, label) {
			var newDiv = createDiv('selected_' + id, label);

			if (listMobile.firstElementChild !== null) {
				viewMore.classList.remove('hide');
				if (viewMore.getAttribute('aria-expanded') === 'false') {
					listMobile.firstElementChild.classList.add('hide');
					listMobile.firstElementChild.classList.remove('displayFlex');
				}
			}
			
			prepend(list, newDiv);

			// need to recreate div to append to other filter box
			newDiv = createDiv('selected_' + id, label);

			prepend(listMobile, newDiv);

			filtersSelected.classList.remove('hide');
			//filtersSelectedMobile.classList.remove('hide');
			showClearAll();
			// moving focus to top of page is disorienting for a11y users
			//document.querySelectorAll('button.selected_' + id)[1].focus();
			//document.getElementsByClassName('selected_' + id)[0].focus(); // remove btn

		};

		const prepend = function(list, child) {
			if (list.hasChildNodes()) {
				list.insertBefore(child, list.firstChild);
			} else {
				list.appendChild(child);
			}
		};

		const createDiv = function(newId, filterName) {
			var newDiv = document.createElement('div');
			newDiv.classList.add(newId);
			newDiv.classList.add('selectedFilter', "displayFlex", "flexAlignItemsCenter", "flexSpaceBetween", "padding10", "text18");
			var filterDiv = '<p class="c-xs-quarter-3 noPadding" role="alert" aria-atomic="true"><span class="visually-hidden">Now Showing </span>' +
				filterName + '</p><button class="noLine darkestLink btnToHyp padding5 ' + newId +
				'" OnClick="productList4i.SelectedFilters.RemoveFilterClick(\'' + newId + '\')" onkeypress="productList4i.SelectedFilters.RemoveFilterKeypress(event, \'' +
				newId + '\')"> <svg class="show" height="20px" width="20px" aria-label="remove ' + filterName +
				' filter" role="img"><title>Remove ' + filterName + ' filter</title><use xlink:href="#svgIcon-remove"></use></svg></button>';
			newDiv.innerHTML = filterDiv;
			return newDiv;
		};

		const removeFilterClick = function(element) {
			var elementId = element.replace('selected_', '');

			if (elementId === 'PriceRange') {
				priceRangeFilter.Clear();
			} else if (elementId === 'txtInnerSearch') {
				subSearchFilter.Clear();
			} else if (filterValues.IsColorId(elementId)) {
				colorSelectorFilter.Remove(document.getElementById(elementId));
			} else {
				filterMenu.MarkUnSelected(document.getElementById(elementId));
			}

			updateFilterUrl.Update(filterValues, prodListValues, false);

			return false;
		};

		const removeFilterKeypress = function(e, element) {
			if (e.which === 13) {
				removeFilterClick(element);
			}
		};

		const addToSelectedFilter = function(element, filterName) {
			if (filterName) {
				addToGroup(element.id, filterName);
			} else if (element.id.indexOf('ImprintLocation') !== -1) {
				addToGroup(element.id, document.getElementById('ImprintLocationCountFilterTitle').value.replace('##IMPLOC##', element.getAttribute('data-filter')));
			} else if (element.id.indexOf('ImprintColor') !== -1) {
				addToGroup(element.id, document.getElementById('ImprintColorCountFilterTitle').value.replace('##IMPCOLOR##', element.getAttribute('data-filter')));
			} else {
				var label = element.getAttribute('data-filter');
				addToGroup(element.id, label);
			}
		};

		const resetViewMoreFilter = function() {
			var label = viewMore.innerHTML;
			if (viewMore.getAttribute('aria-expanded') === 'true') {
				viewMore.setAttribute('aria-expanded', 'false');
			       label = "View All Selected";
				viewMore.innerHTML = label;
				viewMore.setAttribute('data-text', label);
			} else {
				viewMore.setAttribute('aria-expanded', 'true');
			       label = "View Fewer Filters";
				viewMore.innerHTML = label;
				viewMore.setAttribute('data-text', label);
			}

			viewMore.classList.add('hide');
		};

		const removeFromSelectedFilter = function(element) {
			// remove the div in selected filters
			var filterMobile = listMobile.querySelector('.selected_' + element.id);
			var filter = list.querySelector('.selected_' + element.id);

			if (filterMobile !== null) {
				if (listMobile.children.length > 1) {
					list.removeChild(filter);
					listMobile.removeChild(filterMobile);

					listMobile.firstElementChild.classList.remove('hide');

					if (listMobile.children.length === 1) {
						resetViewMoreFilter();
					}
				} else {
					removeAllFromSelectedFilter();
				}
			}
		};

		const removeAllFromSelectedFilter = function() {
			list.innerHTML = '';
			listMobile.innerHTML = '';
			//filtersSelected.classList.add('hide');
			//filtersSelectedMobile.classList.add('hide');
			listMobile.innerHTML = "<p class='marginLeft5'>No filters selected</p>";
			resetViewMoreFilter();
		};

		// Toggle the view more and less selected filters visibility and CSS to change the display.
		
		const toggleSelectedFiltersView = function(e) {
    			e.preventDefault();
			toggleSelectedFilterItems();
			var label = viewMore.innerHTML;
			if (viewMore.getAttribute('aria-expanded') === 'true') {
				viewMore.setAttribute('aria-expanded', 'false');
			       label = "View All Selected";
				viewMore.innerHTML = label;
				viewMore.setAttribute('data-text', label);
			} else {
				viewMore.setAttribute('aria-expanded', 'true');
			       label = "View Fewer Filters";
				viewMore.innerHTML = label;
				viewMore.setAttribute('data-text', label);
			}
		};

		const toggleSelectedFilterItems = function(isExpanded) {
    			
    			for (let i = 1; i < listMobile.children.length; i++) {
        			let item = listMobile.children[i];
        			if (item.classList.contains("hide")) {
            				item.classList.remove('hide');
        			} else {
            				item.classList.add("hide");
        			}
        
        			if (item.classList.contains("hide")) {
            				item.classList.remove('displayFlex');
        			} else {
            				item.classList.add("displayFlex");
        			}
    			}
		};
		
		/*const toggleSelectedFilterItems = function() {
			for (var i = 1; i < listMobile.children.length; i++) {
				listMobile.children[i].classList.toggle('hide');
			}
		};*/

		
		const events = function() {
			clearAllFilters.addEventListener('click', function(e) {
				e.preventDefault();

				filterMenu.UnSelectAllFilters(true);
				hideClearAll();
			});

			clearAllFiltersMobile.addEventListener('click', function(e) {
				e.preventDefault();

				filterMenu.UnSelectAllFilters(true);
				hideClearAll();
			});

			viewMore.addEventListener('click', toggleSelectedFiltersView);
		};

		const hideClearAll = function() {
			clearAllFilters.classList.add('hide');
			clearAllFiltersMobile.classList.add('hide');
		};

		const showClearAll = function() {
			clearAllFilters.classList.remove('hide');
			clearAllFiltersMobile.classList.remove('hide');
		};

		return {
			AddToSelectedFilter: addToSelectedFilter,
			RemoveFilterClick: removeFilterClick,
			RemoveFilterKeypress: removeFilterKeypress,
			RemoveFromSelectedFilter: removeFromSelectedFilter,
			RemoveAllFromSelectedFilter: removeAllFromSelectedFilter,
			ToggleSelectedFiltersView: toggleSelectedFiltersView,
			Events: events,
			get SelectedFilterList() {
				return list;
			},
		};
	})();

	const filterMenu = (function() {
		const overflow = 10;
		const menuContainer = document.getElementById('menuContainer');
		const filterMenuMobile = document.getElementById('filterMenuMobile');
		const hiddenTags = document.getElementById('hiddenTags');

		var openMenuOption;

		// Pass in false if the filter does not need to be logged
		const markSelected = function(element, logFilter) {
			if (filterValues.IsColorId(element.id)) {
				colorSelectorFilter.Update(element);
			} else {
				if (typeof (logFilter) === 'undefined') logFilter = true;
				element.checked = true;
				filterValues.AddFilter(element.id, logFilter);
				selectedFilters.AddToSelectedFilter(element);
			}
		};

		const markUnSelected = function(element) {
			element.checked = false;
			filterValues.RemoveFilter(element.id);
			selectedFilters.RemoveFromSelectedFilter(element);
		};

		const setFilterOrder = function(id, logFilter) {
			if (typeof (logFilter) === 'undefined') logFilter = true;
			const element = document.getElementById('sortDropDown');
			element.value = id;
			filterValues.AddFilter(id, logFilter);
		};

		const setPageSize = function(id) {
			document.getElementById('itemsPerPageDropDown').value = id;
			filterValues.AddPageSize(id);
		};

		const markGroupUnSelectedFilter = function(list) {
			var element;
			for (var i = 0; i < list.length; i++) {
				element = document.getElementById(list[i]);
				element.checked = false;
			}
		};

		const unSelectAllFilters = function(updateUrl) {
			const colorFilterValues = filterValues.GetColorFilter();
			var colorValuesWithElementID = [];
			for (var i = 0; i < colorFilterValues.length; i++) {
				colorValuesWithElementID.push(filterValues.getColorElementId(colorFilterValues[i]));
			}

			const ratingFilterValues = filterValues.GetRatingFilter();
			var ratingValuesWithElementID = [];
			for (var i = 0; i < ratingFilterValues.length; i++) {
				ratingValuesWithElementID.push(filterValues.GetRatingElementId(ratingFilterValues[i]));
			}

			const productTimeFilterValues = filterValues.GetProdTimeFilter();
			var productTimeValuesWithElementID = [];
			for (var i = 0; i < productTimeFilterValues.length; i++) {
				productTimeValuesWithElementID.push(filterValues.GetProductionTimeElementId(productTimeFilterValues[i]));
			}

			const imprintLocationCountFilterValues = filterValues.GetImprintLocationCountFilter();
			var imprintLocationCountValuesWithElementID = [];
			for (var i = 0; i < imprintLocationCountFilterValues.length; i++) {
				imprintLocationCountValuesWithElementID.push(filterValues.GetImprintLocationCountElementId(imprintLocationCountFilterValues[i]));
			}

			const imprintColorCountFilterValues = filterValues.GetImprintColorCountFilter();
			var imprintColorCountValuesWithElementID = [];
			for (var i = 0; i < imprintColorCountFilterValues.length; i++) {
				imprintColorCountValuesWithElementID.push(filterValues.GetImprintColorCountElementId(imprintColorCountFilterValues[i]));
			}

			const tagFilterValues = filterValues.GetTagFilter();
			var tagValuesWithElementID = [];
			for (var i = 0; i < tagFilterValues.length; i++) {
				tagValuesWithElementID.push(filterValues.GetTagElementId(tagFilterValues[i]));
			}

			// Unselect current filtering.
			markGroupUnSelectedFilter(ratingValuesWithElementID);
			markGroupUnSelectedFilter(productTimeValuesWithElementID);
			markGroupUnSelectedFilter(imprintLocationCountValuesWithElementID);
			markGroupUnSelectedFilter(imprintColorCountValuesWithElementID);
			markGroupUnSelectedFilter(tagValuesWithElementID);
			colorSelectorFilter.Clear(colorValuesWithElementID);
			if(document.getElementById('PriceRange') !== null){
				priceRangeFilter.Clear();
			}
			subSearchFilter.Clear();

			// Remove all filter values.
			filterValues.ClearFilters();

			// Remove all values from the table.
			selectedFilters.RemoveAllFromSelectedFilter();

			if (updateUrl) {
				// Update the filter url, products and other information.
				updateFilterUrl.Update(filterValues, prodListValues, false);
			}
		};

		const selectMainMenu = function(menu) {
			menu.classList.add('active');
			menu.setAttribute('aria-expanded', 'true');

			var element = document.getElementById(menu.getAttribute('aria-controls'));
			element.classList.remove('hide');

			var listItems = element.querySelectorAll('li:not(.hide)');

			if (listItems.length) {
				setTimeout(function(){
					listItems[0].children[0].focus();
				}, 100);
				listItems[0].children[0].addEventListener('keydown', shiftTabKeyPress);
				listItems[listItems.length - 1].children[0].addEventListener('keydown', tabKeyPress);
			}
		};

		const unSelectMainMenu = function(menu) {
			menu.classList.remove('active');
			menu.setAttribute('aria-expanded', 'false');

			var element = document.getElementById(menu.getAttribute('aria-controls'));

			var listItems = element.querySelectorAll('li:not(.hide)');

			if (listItems.length) {
				listItems[0].children[0].removeEventListener('keydown', shiftTabKeyPress);
				listItems[listItems.length - 1].children[0].addEventListener('keydown', tabKeyPress);
			}

			element.classList.add('hide');

			Array.prototype.forEach.call(menuContainer.getElementsByClassName('filterMenu'), function(el) {
				hideFilterOption(el);
			});
		};

		const hideFilterOption = function(element) {
			if (element.classList.contains('overflow')) {
				element.classList.add('hideOnSmallAndMedium');
			} else {
				element.classList.add('showOnLarge');
			}
		};

		const selectFilterMenu = function(menu) {
			if(menu){
				menu.classList.remove('showOnLarge');
				menu.classList.remove('hideOnSmallAndMedium');
				menu.classList.remove('hide');
	
				const listItems = menu.querySelectorAll('li:not(.hide)');
	
				if (listItems.length) {
					listItems[0].children[0].focus();
					listItems[0].children[0].addEventListener('keydown', shiftTabKeyPress);
					listItems[listItems.length - 1].children[0].addEventListener('keydown', tabKeyPress);
				} else {
					openMenuOption = menu;
					menu.querySelector('.filterMenuSelect').disabled = false;
					menu.querySelector('.filterMenuSelect').focus();
					menu.querySelector('.filterMenuSelect').addEventListener('keydown', shiftTabKeyPress);
	
					Array.prototype.forEach.call(menu.querySelectorAll('.lastObject'), function(element) {
						element.addEventListener('keydown', tabKeyPress);
					});
				}
			}
		};

		const unSelectFilterMenu = function(element) {
			const listItems = element.parentElement.parentElement.querySelectorAll('li:not(.hide)');

			if (listItems.length) {
				listItems[0].children[0].removeEventListener('keydown', shiftTabKeyPress);
				listItems[listItems.length - 1].children[0].removeEventListener('keydown', tabKeyPress);
			}

			var parent = element.parentElement.parentElement;

			if (parent.classList.contains('filterMenu')) {
				hideFilterOption(parent);
				parent.querySelector('.filterMenuSelect').removeEventListener('keydown', shiftTabKeyPress);
				parent.querySelector('.filterMenuSelect').disabled = true;

				Array.prototype.forEach.call(parent.querySelectorAll('.lastObject'), function(element) {
					element.removeEventListener('keydown', tabKeyPress);
				});
			} else {
				parent.classList.add('hide');
			}
		};

		const markSelectedReload = function(currentIds, newIds, getId) {
			var filters = '';

			for (var i = 0; i < newIds.length; i++) {
				if (currentIds.indexOf(newIds[i]) === -1) {
					filters += '#' + getId(newIds[i]) + ',';
				}
			}

			if (filters.length) {
				var list = document.querySelectorAll(filters.substr(0, filters.length - 1));

				for (var j = 0; j < list.length; j++) {
					markSelected(list[j], false);
				}
			}
		};

		const markUnSelectedReload = function(currentIds, newIds, getId, endIndex) {
			var filters = '';

			for (var i = 0; i < endIndex; i++) {
				if (newIds.indexOf(currentIds[i]) === -1) {
					filters += '#' + getId(currentIds[i]) + ',';
				}
			}

			if (filters.length) {
				var list = document.querySelectorAll(filters.substr(0, filters.length - 1));

				for (var j = 0; j < list.length; j++) {
					markUnSelected(list[j]);
				}
			}
		};

		const updateMenuSelections = function(options) {
			const mainMenu = filterMenuMobile.querySelectorAll('a:not(.moreLink):not(.noHide)');
			var mainMenuCnt = 0;

			addTagFilters();

			for (var i = 0; i < mainMenu.length; i++) {
				if (updateMenuOptions(mainMenu[i].getAttribute('aria-controls'), options)) {
					mainMenu[i].parentElement.classList.add('hide');
					mainMenu[i].parentElement.classList.remove('overflow');
				} else {
					if ((++mainMenuCnt) <= overflow) {
						mainMenu[i].parentElement.classList.remove('hide');
						mainMenu[i].parentElement.classList.remove('overflow');
					} else {
						mainMenu[i].parentElement.classList.add('hide');
						mainMenu[i].parentElement.classList.add('overflow');
					}
				}
			}

			if (mainMenuCnt > overflow) {
				resetMoreLink(filterMenuMobile.getElementsByClassName('moreLink'));
			} else {
				Array.prototype.forEach.call(filterMenuMobile.getElementsByClassName('moreLink'), function(el) {
					el.parentElement.classList.add('hide');
				});
			}
		};

		const updateMenuOptions = function(menuId, options) {
			const filterOptions = options[menuId];

			if(menuId === 'colorFilter') {
				colorSelectorFilter.UpdateCounts(filterOptions);
				return false;
			}
			
			if(document.getElementById(menuId)){
				const filters = [].slice.call(document.getElementById(menuId).querySelectorAll('input[type=checkbox], input[type=radio]'));
				if (filters.length){
					let optionCnt;
					let filterId = '';
					for (let i = 0; i < filters.length; i++) {
						optionCnt = 0;
						filterId = filterValues.GetFilterId(filters[i].id);
						if (filterId && filterOptions) {
							for (let j = 0; j < filterOptions.length; j++) {
								if (filterOptions[j].Id === filterId) {
									optionCnt = filterOptions[j].Count;
									break;
								}
							}
							
						}
						common.GetUpdatedFilterCount(document.getElementById(filters[i].id + '_label'), optionCnt, filters[i].getAttribute('data-filter'));
					}
				}
				return false;
			}
			return false;
		};

		const resetMoreLink = function(moreLink) {
			if (moreLink.length) {
				var element = moreLink[0];
				element.parentElement.classList.remove('hide');

				if (element.getAttribute('aria-expanded') === 'true') {
					//var newLabel = element.getAttribute('data-toggle-label');
					var newLabel = element.innerHTML.replace("Less", "More");
					//element.setAttribute('data-toggle-label', element.innerHTML);
					element.innerHTML = newLabel;
					element.querySelector("svg").setAttribute("class", "rotate180");
					element.setAttribute('aria-expanded', 'false');
				}
			}
		};

		const tabKeyPress = function(e) {
			if (e.which === 9 && !e.shiftKey) {
				const element = this.parentElement.parentElement;
				const listItems = element.querySelectorAll('li:not(.hide)');

				if (listItems.length) {
					e.preventDefault();
					listItems[0].children[0].focus();
				} else if (mobileFilterDropDown.classList.contains('active') && getComputedStyle(element.querySelector('.filterMenuSelect'), null).display !== 'none') {
					e.preventDefault();
					element.querySelector('.filterMenuSelect').focus();
				}
			}
		};

		const shiftTabKeyPress = function(e) {
			if (e.which === 9 && e.shiftKey) {
				e.preventDefault();

				mobileFilterDropDown.focus();
			}
		};
		
		/*const toggleFilterOptions = function(e) {
			e.preventDefault();

			if (menuContainer.classList.contains('hide')) {
				menuContainer.classList.remove('hide');
				productContainer.classList.remove('c-md-13');
				productContainer.classList.add('c-md-10');
				this.setAttribute('aria-expanded', true);

				menuContainer.querySelector('.filterMenu button.accordionHeader').focus();
			} else {
				menuContainer.classList.add('hide');
				productContainer.classList.add('c-md-13');
				productContainer.classList.remove('c-md-10');
				this.setAttribute('aria-expanded', false);
			}

			common.ToggleButtonLabel(this);
		};*/

		const toggleFilterOptions = function(e) {
			e.preventDefault();

			if (menuContainer.classList.contains('hide')) {
				menuContainer.classList.remove('hide');
				productContainer.classList.remove('c-md-13');
				productContainer.classList.add('c-md-10');
				this.setAttribute('aria-expanded', true);

				//menuContainer.querySelector('.filterMenu button.accordionHeader').focus();
				const accordionHeader = menuContainer.querySelector('.filterMenu button.accordionHeader');
				if (accordionHeader) {
            				accordionHeader.focus();
           				accordionHeader.removeEventListener('click', toggleFilterOptions); // Prevent duplicate listener
        			}	
			} else {
				menuContainer.classList.add('hide');
				productContainer.classList.add('c-md-13');
				productContainer.classList.remove('c-md-10');
				this.setAttribute('aria-expanded', false);
			}

			common.ToggleButtonLabel(this);
		};
		

		const toggleTagFilters = function(e) {
			e.preventDefault();

			var filterId = "MoreFiltersButton", filtervalue = "More";

			const expanded = (this.getAttribute('aria-expanded') === 'true');

			if (mobileFilterDropDown.classList.contains('active')) {
				mobileFilterDropDown.click();
			}

			this.setAttribute('aria-expanded', !expanded);
			var newLabel = this.innerHTML;

			if (expanded) {
				filtervalue = "Less";
				this.innerHTML = newLabel.replace("Less", "More");
				this.querySelector("svg").setAttribute("class", "rotate180");
				dataService.FilterLog(filterId, filtervalue);
				//document.getElementById('pnlSubSearch').scrollIntoView({ behavior: 'smooth' });
			} else {
				this.innerHTML = newLabel.replace("More", "Less");
				this.querySelector("svg").removeAttribute("class");
				dataService.FilterLog(filterId, filtervalue);
				addTagFilters();
				setTimeout(function(){
					document.getElementById("tagFiltersDiv").querySelector("button").focus();
				}, 100);
			}

			//common.ToggleButtonLabel(this);

			Array.prototype.forEach.call(menuContainer.querySelectorAll('.filterMenu.overflow'), function(element) {
				if (expanded) {
					element.classList.add('hide');
					element.classList.remove('hideOnSmallAndMedium');
				} else {
					element.classList.remove('hide');
					element.classList.add('hideOnSmallAndMedium');
				}
			});
			
			filterAccordions.init(document.getElementById("mainContent"));
		};

		const moreLinkClickEvent = function(event, element) {
			event.preventDefault();

			var parent = element.parentElement.parentElement;
			var newLabel;
			//var newLabel = element.getAttribute('data-toggle-label');
			//element.setAttribute('data-toggle-label', element.innerHTML);
			//element.innerHTML = newLabel;

			if (element.getAttribute('aria-expanded') === 'true') {
				Array.prototype.forEach.call(parent.getElementsByClassName('overflow'), function(el) {
					el.classList.add('hide');
				});
				newLabel = element.innerHTML.replace("Less", "More");
				element.setAttribute('aria-expanded', 'false');
				element.innerHTML = newLabel;
				element.querySelector("svg").setAttribute("class", "rotate180");
			} else {
				var overflow = parent.getElementsByClassName('overflow');

				if (overflow.length) {
					Array.prototype.forEach.call(overflow, function(el) {
						el.classList.remove('hide');
					});

					overflow[0].children[0].focus();
				}
				newLabel = element.innerHTML.replace("More", "Less");
				element.setAttribute('aria-expanded', 'true');
				element.innerHTML = newLabel;
				element.querySelector("svg").removeAttribute("class");
			}
		};

		const checkboxClickEvent = function(element) {
			if (element.checked) {
				filterMenu.MarkSelected(element);
			} else {
				filterMenu.MarkUnSelected(element);
			}

			if (mobileFilterDropDown.classList.contains('active')) {
				mobileFilterDropDown.click();
			}

			updateFilterUrl.Update(filterValues, prodListValues, false);
		};

		const mobileMenuSelectEvent = function(event, element) {
			event.preventDefault();

			if (document.querySelector(".openedFilter")) {
				document.querySelector(".openedFilter").setAttribute("aria-expanded", "false");
				document.querySelector(".openedFilter").classList.remove("openedFilter");
			}
			filterMenu.UnSelectFilterMenu(element);
			filterMenu.SelectFilterMenu(document.getElementById(element.getAttribute('aria-controls')));
		};

		const events = function() {
			Array.prototype.forEach.call(menuContainer.getElementsByClassName('filterMenuSelect'), function(el) {
				el.addEventListener('click', function(e) {
					mobileMenuSelectEvent(e, this);
				});
			});

			Array.prototype.forEach.call(menuContainer.getElementsByClassName('filterMainMenuSelect'), function(el) {
				el.addEventListener('click', function(e) {
					e.preventDefault();
					el.setAttribute("aria-expanded", "true");
					el.classList.add("openedFilter");
					filterMenu.UnSelectFilterMenu(this.parentElement);
					filterMenu.SelectFilterMenu(document.getElementById(this.getAttribute('aria-controls')));
					if (el != document.getElementById("a_subSearchFilter")) {
					    document.getElementById('pnlSubSearch').scrollIntoView({ behavior: 'smooth' });
					} else {
					    document.getElementById("subSearchContent").classList.add("open");
					    document.getElementById("txtInnerSearch").focus();
					}
				});
			});

			Array.prototype.forEach.call(menuContainer.getElementsByClassName('moreLink'), function(el) {
				el.addEventListener('click', function(e) {
					moreLinkClickEvent(e, this);
				});
			});

			Array.prototype.forEach.call(menuContainer.querySelectorAll('input[type=checkbox]'), function(el) {
				el.addEventListener('change', function() {
					checkboxClickEvent(this);
				});
			});
		};

		const addTagFilters = function() {
			if (hiddenTags && hiddenTags.textContent.length) {
				const tagFilters = document.getElementById('tagFiltersDiv');
				tagFilters.innerHTML = hiddenTags.textContent;
				hiddenTags.textContent = '';

				Array.prototype.forEach.call(tagFilters.getElementsByClassName('moreLink'), function(el) {
					el.addEventListener('click', function(e) {
						moreLinkClickEvent(e, this);
					});
				});

				Array.prototype.forEach.call(tagFilters.querySelectorAll('input[type=checkbox]'), function(el) {
					el.addEventListener('change', function() {
						checkboxClickEvent(this);
					});
				});

				Array.prototype.forEach.call(tagFilters.getElementsByClassName('filterMenuSelect'), function(el) {
					el.addEventListener('click', function(e) {
						mobileMenuSelectEvent(e, this);
					});
				});
				
				//filterAccordions.init(document.getElementById("mainContent"));
			}
		};

		return {
			MarkSelected: markSelected,
			MarkUnSelected: markUnSelected,
			SetFilterOrder: setFilterOrder,
			SetPageSize: setPageSize,
			MarkGroupUnSelectedFilter: markGroupUnSelectedFilter,
			SelectMainMenu: selectMainMenu,
			UnSelectMainMenu: unSelectMainMenu,
			MarkSelectedReload: markSelectedReload,
			MarkUnSelectedReload: markUnSelectedReload,
			UpdateMenuSelections: updateMenuSelections,
			SelectFilterMenu: selectFilterMenu,
			UnSelectFilterMenu: unSelectFilterMenu,
			UnSelectAllFilters: unSelectAllFilters,
			ToggleFilterOptions: toggleFilterOptions,
			ToggleTagFilters: toggleTagFilters,
			Events: events,
			AddTagFilters: addTagFilters,
			get OpenMenuOption() {
				return openMenuOption;
			},
			get GetMenuContainer() {
				return menuContainer;
			}
		};
	}());

	const filterValues = (function() {
		var priceFilter = [];
		var colorFilter = [];
		var ratingFilter = [];
		var prodTimeFilter = [];
		var imprintLocationCountFilter = [];
		var imprintColorCountFilter = [];
		var tagFilter = [];
		var sortFilter = '';
		var pageNumber = 0;
		var pageSize = 0;
		var subSearchFilter = '';
		const colorId = 'chkColor_';
		const ratingId = 'chkRating_';
		const tagId = 'chkTag_';
		const prodTimeId = 'chkProductionTime_';
		const imprintLocationCountId = 'chkImprintLocationCount_';
		const imprintColorCountId = 'chkImprintColorCount_';
		const sortId = 'chksort_';
		const defaultSort = '-RelevenceScore';
		const defaultPageSize = 50;

		// Pass in false if the filter does not need to be logged .
		const addFilter = function(filterId, logFilter) {
			if (typeof (logFilter) === 'undefined') logFilter = true;

			if (filterId.indexOf(colorId) === 0) {
				var filterIdValue = filterId.replace(colorId, '');
				colorFilter.push(filterIdValue);

				if (logFilter === true) {
					dataService.FilterLog(filterId, filterIdValue);
				}
			} else if (filterId.indexOf(ratingId) === 0) {
				var filterIdValue = filterId.replace(ratingId, '');
				ratingFilter.push(filterIdValue);

				if (logFilter === true) {
					dataService.FilterLog(filterId, filterIdValue);
				}
			} else if (filterId.indexOf(prodTimeId) === 0) {
				var filterIdValue = filterId.replace(prodTimeId, '');
				prodTimeFilter.push(filterIdValue);

				if (logFilter === true) {
					dataService.FilterLog(filterId, filterIdValue);
				}
			} else if (filterId.indexOf(imprintLocationCountId) === 0) {
				var filterIdValue = filterId.replace(imprintLocationCountId, '');
				imprintLocationCountFilter.push(filterIdValue);

				if (logFilter === true) {
					dataService.FilterLog(filterId, filterIdValue);
				}
			} else if (filterId.indexOf(imprintColorCountId) === 0) {
				var filterIdValue = filterId.replace(imprintColorCountId, '');
				imprintColorCountFilter.push(filterIdValue);

				if (logFilter === true) {
					dataService.FilterLog(filterId, filterIdValue);
				}
			} else if (filterId.indexOf(tagId) === 0) {
				var filterIdValue = filterId.replace(tagId, '');
				tagFilter.push(filterIdValue);

				if (logFilter === true) {
					dataService.FilterLog(filterId, filterIdValue);
				}
			} else if (filterId.indexOf(sortId) === 0) {
				sortFilter = filterId.replace(sortId, '');

				if (logFilter === true) {
					dataService.FilterLog('sortDropDown', sortFilter);
				}
			}
		};

		const addpriceRangeFilter = function(filterId, priceRangeMin, priceRangeMax, logFilter) {
			if (typeof (logFilter) === 'undefined') logFilter = true;
			while (priceFilter.length) {
				priceFilter.shift();
			}

			priceFilter.push(priceRangeMin);
			priceFilter.push(priceRangeMax);
			var filtervalue = priceRangeMin + '-' + priceRangeMax;
			if (logFilter === true) {
				dataService.FilterLog(filterId, filtervalue);
			}
		};

		const addSubSearchFilter = function(filterId, subSearch, logFilter) {
			if (typeof (logFilter) === 'undefined') logFilter = true;
			subSearchFilter = subSearch;
			if (logFilter === true) {
				dataService.FilterLog(filterId, subSearch);
			}
		};

		const getSelectedFilters = function(filterId) {
			if (filterId.indexOf(colorId) === 0) {
				return getColorFilter();
			} else if (filterId.indexOf(ratingId) === 0) {
				return getRatingFilter();
			} else if (filterId.indexOf(prodTimeId) === 0) {
				return getProdTimeFilter();
			} else if (filterId.indexOf(imprintLocationCountId) === 0) {
				return getImprintLocationCountFilter();
			} else if (filterId.indexOf(imprintColorCountId) === 0) {
				return getImprintColorCountFilter();
			} else if (filterId.indexOf(tagId) === 0) {
				return getTagFilter();
			} else {
				return [];
			}
		};

		const getFilterId = function(filterId) {
			if (filterId.indexOf(colorId) === 0) {
				return filterId.replace(colorId, '');
			} else if (filterId.indexOf(ratingId) === 0) {
				return filterId.replace(ratingId, '');
			} else if (filterId.indexOf(prodTimeId) === 0) {
				return filterId.replace(prodTimeId, '');
			} else if (filterId.indexOf(imprintLocationCountId) === 0) {
				return filterId.replace(imprintLocationCountId, '');
			} else if (filterId.indexOf(imprintColorCountId) === 0) {
				return filterId.replace(imprintColorCountId, '');
			} else if (filterId.indexOf(tagId) === 0) {
				return filterId.replace(tagId, '');
			} else if (filterId.indexOf(sortId) === 0) {
				return filterId.replace(sortId, '');
			}
		};

		const addPageNumber = function(pageNo) {
			pageNumber = pageNo;
		};

		const addPageSize = function(size) {
			pageSize = size;
		};

		const removeFilter = function(filterId) {
			var index;

			if (filterId.indexOf(colorId) === 0) {
				index = colorFilter.indexOf(filterId.replace(colorId, ''));
				if (index > -1) {
					colorFilter.splice(index, 1);
				}
			} else if (filterId.indexOf(ratingId) === 0) {
				index = ratingFilter.indexOf(filterId.replace(ratingId, ''));
				if (index > -1) {
					ratingFilter.splice(index, 1);
				}
			} else if (filterId.indexOf(prodTimeId) === 0) {
				index = prodTimeFilter.indexOf(filterId.replace(prodTimeId, ''));
				if (index > -1) {
					prodTimeFilter.splice(index, 1);
				}
			} else if (filterId.indexOf(imprintLocationCountId) === 0) {
				index = imprintLocationCountFilter.indexOf(filterId.replace(imprintLocationCountId, ''));
				if (index > -1) {
					imprintLocationCountFilter.splice(index, 1);
				}
			} else if (filterId.indexOf(imprintColorCountId) === 0) {
				index = imprintColorCountFilter.indexOf(filterId.replace(imprintColorCountId, ''));
				if (index > -1) {
					imprintColorCountFilter.splice(index, 1);
				}
			} else if (filterId.indexOf(tagId) === 0) {
				index = tagFilter.indexOf(filterId.replace(tagId, ''));
				if (index > -1) {
					tagFilter.splice(index, 1);
				}
			} else if (filterId.indexOf(sortId) === 0) {
				sortFilter = defaultSort;
			}
		};

		const removepriceRangeFilter = function() {
			while (priceFilter.length) {
				priceFilter.shift();
			}
		};

		const removeSubSearchFilter = function() {
			subSearchFilter = '';
		};

		const clearFilters = function() {
			pageNumber = 0;
			subSearchFilter = '';

			while (priceFilter.length) {
				priceFilter.shift();
			}
			while (colorFilter.length) {
				colorFilter.shift();
			}
			while (ratingFilter.length) {
				ratingFilter.shift();
			}
			while (prodTimeFilter.length) {
				prodTimeFilter.shift();
			}
			while (imprintLocationCountFilter.length) {
				imprintLocationCountFilter.shift();
			}
			while (imprintColorCountFilter.length) {
				imprintColorCountFilter.shift();
			}
			while (tagFilter.length) {
				tagFilter.shift();
			}
		};

		const getColorElementId = function(color) {
			return colorId + color;
		};

		const getRatingElementId = function(rating) {
			return ratingId + rating;
		};

		const getProductionTimeElementId = function(time) {
			return prodTimeId + time;
		};

		const getImprintLocationCountElementId = function(imprintLocationCount) {
			return imprintLocationCountId + imprintLocationCount;
		};

		const getImprintColorCountElementId = function(imprintColorCount) {
			return imprintColorCountId + imprintColorCount;
		};

		const getTagElementId = function(tag) {
			return tagId + tag;
		};

		const getSortElementId = function(sort) {
			return sortId + sort;
		};

		const getPriceFilter = function() {
			return priceFilter;
		};

		const getColorFilter = function() {
			return colorFilter;
		};

		const getRatingFilter = function() {
			return ratingFilter;
		};

		const getProdTimeFilter = function() {
			return prodTimeFilter;
		};

		const getImprintLocationCountFilter = function() {
			return imprintLocationCountFilter;
		};

		const getImprintColorCountFilter = function() {
			return imprintColorCountFilter;
		};

		const getTagFilter = function() {
			return tagFilter;
		};

		const getSortFilter = function() {
			return sortFilter;
		};

		const getPageNumber = function() {
			return pageNumber;
		};

		const getPageSize = function() {
			return pageSize;
		};

		const getDefaultSort = function() {
			return defaultSort;
		};

		const getDefaultPageSize = function() {
			return defaultPageSize;
		};

		const getSubSearchFilter = function() {
			return subSearchFilter;
		};

		const isColorId = function(filterId) {
			return filterId.indexOf(colorId) === 0;
		};

		const isRatingId = function(filterId) {
			return filterId.indexOf(ratingId) === 0;
		};

		return {
			AddFilter: addFilter,
			AddpriceRangeFilter: addpriceRangeFilter,
			AddSubSearchFilter: addSubSearchFilter,
			ClearFilters: clearFilters,
			RemoveFilter: removeFilter,
			RemovepriceRangeFilter: removepriceRangeFilter,
			RemoveSubSearchFilter: removeSubSearchFilter,
			AddPageNumber: addPageNumber,
			AddPageSize: addPageSize,
			GetPriceFilter: getPriceFilter,
			GetColorFilter: getColorFilter,
			GetRatingFilter: getRatingFilter,
			GetProdTimeFilter: getProdTimeFilter,
			GetImprintLocationCountFilter: getImprintLocationCountFilter,
			GetImprintColorCountFilter: getImprintColorCountFilter,
			GetTagFilter: getTagFilter,
			GetSortFilter: getSortFilter,
			GetDefaultSort: getDefaultSort,
			GetPageNumber: getPageNumber,
			GetPageSize: getPageSize,
			getColorElementId: getColorElementId,
			GetRatingElementId: getRatingElementId,
			GetSortElementId: getSortElementId,
			GetProductionTimeElementId: getProductionTimeElementId,
			GetImprintLocationCountElementId: getImprintLocationCountElementId,
			GetImprintColorCountElementId: getImprintColorCountElementId,
			GetTagElementId: getTagElementId,
			GetSelectedFilters: getSelectedFilters,
			GetFilterId: getFilterId,
			GetSubSearchFilter: getSubSearchFilter,
			GetDefaultPageSize: getDefaultPageSize,
			IsColorId: isColorId,
			IsRatingId: isRatingId,
		};
	}());

	const eventListners = (function() {
		document.getElementById('filterDropDown').addEventListener('click', function(e) {
			e.preventDefault();

			if (this.classList.contains('active')) {
				this.parentElement.classList.remove('active');
				filterMenu.UnSelectMainMenu(this);
				if (document.querySelector(".openedFilter")) {
					document.querySelector(".openedFilter").setAttribute("aria-expanded", "false");
					document.querySelector(".openedFilter").classList.remove("openedFilter");
				}
				this.focus();
			} else {
				this.parentElement.classList.add('active');
				filterMenu.SelectMainMenu(this);
				filterMenu.AddTagFilters();
			}
		});

		document.getElementById('filterDropDown').addEventListener('keydown', function(e) {
			if (e.which === 9 && !e.shiftKey && this.classList.contains('active')) {
				e.preventDefault();

				var menu = document.getElementById('filterMenuMobile');

				if (menu.classList.contains('hide')) {
					filterMenu.OpenMenuOption.querySelector('.filterMenuSelect').focus();
				} else {
					var listItems = document.getElementById('filterMenuMobile').querySelectorAll('li:not(.hide)');

					if (listItems.length) {
						listItems[0].children[0].focus();
					}
				}
			}
		});

		document.getElementById('sortDropDown').addEventListener('change', function(e) {
			filterMenu.SetFilterOrder(this.value);
			updateFilterUrl.Update(filterValues, prodListValues, true);
		});

		document.getElementById('sortDropDown').addEventListener('click', function(e) {
			filterMenu.UnSelectMainMenu(document.getElementById(this.getAttribute('data-disable-id')));
		});

		document.getElementById('itemsPerPageDropDown').addEventListener('change', function(e) {
			filterValues.AddPageSize(this.value);
			filterValues.AddPageNumber(1);
			updateFilterUrl.Update(filterValues, prodListValues, true, true, true);
		});

		document.getElementById('toggleFilterSideBar').addEventListener('click', filterMenu.ToggleFilterOptions);

		const toggleTagFilters = document.getElementById('toggleTagFilters');
		toggleTagFilters && toggleTagFilters.addEventListener('click', filterMenu.ToggleTagFilters);

		window.onpopstate = function(event) {
			if (event.state !== null) {
				document.title = event.state.title;
				//document.querySelector('meta[name="keywords"]').setAttribute('content', event.state.keywords);
				document.querySelector('meta[name="description"]').setAttribute('content', event.state.description);
			} else {
				document.title = '';
				//document.querySelector('meta[name="keywords"]').setAttribute('content', '');
				document.querySelector('meta[name="description"]').setAttribute('content', '');
			}

			if (mobileFilterDropDown.classList.contains('active')) {
				filterMenu.UnSelectMainMenu(mobileFilterDropDown);
			}

			updatePageFromUrl.Update();

			updateFilterUrl.Update(filterValues, prodListValues, true, false, true);
		};

		const pagerEvent = function() {
			Array.prototype.forEach.call(document.getElementsByClassName('pagerLink'), function(el) {
				el.addEventListener('click', function(e) {
					e.preventDefault();
					var pageNo = this.getAttribute('data-page-number');

					if (!isNaN(pageNo)) {
						document.getElementById('mainContent').scrollIntoView({ behavior: 'smooth' });
						filterValues.AddPageNumber(pageNo);
						updateFilterUrl.Update(filterValues, prodListValues, true);
					}
				});
			});
		};
		
		/*const getBCInfo = function(el) { 
			var productid = el.getAttribute('data-productid');
			
			if (!isNaN(productid)) {
				dataService.BetterChoicesDialog(productid);
			}
		};*/
		
		/*const betterChoicesEvent = function () {
			const bcButtons = [].slice.call(document.querySelectorAll('.betterChoicesButton'));
			if(bcButtons.length > 0){
				let bcLogo = '<svg height="32px" width="102px" role="img" aria-label="Better Choices"><title>Better Choices</title> <use xlink:href="#svgLogo-betterChoices"></use> </svg>';
				let dialogContent = '<div class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading better choices, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
				
				let options = {
					triggerElements: bcButtons,
					dialogClass: "betterChoicesContent smallDialog", 
					title: bcLogo, 
					content: dialogContent, 
					onOpen: getBCInfo
				};
			
				betterChoicesModal.Init(options);
			}
		};*/

		return {
			PagerEvent: pagerEvent
			//BetterChoicesEvent: betterChoicesEvent,
		};
	})();

	const updateFilterUrl = (function() {
		const advancedSearchUrl = document.getElementById('QueryUrl');

		const update = function(filterValues, prodListValues, updatePageNumber, updateUrl, forceGetProducts) {
			if (typeof (updateUrl) === 'undefined') updateUrl = true;
			if (typeof (forceGetProducts) === 'undefined') forceGetProducts = false;

			const pathName = urlPath();

			var newPath = updatedPriceFilterRouteParam(filterValues.GetPriceFilter()) +
				updatedColorFilterRouteParam(filterValues.GetColorFilter()) +
				updatedRatingFilterRouteParam(filterValues.GetRatingFilter()) +
				updatedProdTimeRouteParam(filterValues.GetProdTimeFilter()) +
				updatedImprintLocationCountRouteParam(filterValues.GetImprintLocationCountFilter()) +
				updatedImprintColorCountRouteParam(filterValues.GetImprintColorCountFilter()) +
				updatedTagFilterRouteParam(filterValues.GetTagFilter());

			if (filterValues.GetColorFilter().length === 0 && filterValues.GetRatingFilter().length === 0 && filterValues.GetProdTimeFilter().length === 0 && filterValues.GetTagFilter().length === 0 &&
				filterValues.GetPriceFilter().length === 0 && filterValues.GetSubSearchFilter().length === 0 &&
				filterValues.GetImprintLocationCountFilter().length === 0 && filterValues.GetImprintColorCountFilter().length === 0) {
				selectedFilters.RemoveAllFromSelectedFilter();
			}

			// If filtering is applied then set the page to the beginning in case the number of pages decreases.
			if (updatePageNumber !== true) {
				filterValues.AddPageNumber(1);
			}

			newPath += updatedFilterQueryString(filterValues.GetPageNumber(), prodListValues.SortMap, filterValues.GetSortFilter(), filterValues.GetSubSearchFilter(), filterValues.GetPageSize());

			if (!forceGetProducts && window.location.pathname + window.location.search === pathName + newPath) {
				return;
			}

			updateCanonicalUrl(newPath);

			newPath = pathName + newPath;

			dataService.GetProducts(newPath, updateUrl, newPath);
		};

		const updateCanonicalUrl = function(path) {
			const canonical = document.querySelector('link[rel="canonical"]');
			if (canonical !== null) {
				canonical.href = getBaseUrl(canonical.href) + path;
			}

			const amp = document.querySelector('link[rel="amphtml"]');
			if (amp !== null) {
				amp.href = getBaseUrl(amp.href) + path;
			}
		};

		const getBaseUrl = function(url) {
			const matches = prodListValues.ParamPattern.exec(url);
			var baseUrl = '';

			if (matches !== null) {
				baseUrl = url.slice(0, matches.index);
			} else if (url.indexOf('?') >= 0) {
				baseUrl = url.slice(0, url.indexOf('?'));
			} else {
				baseUrl = url;
			}

			return baseUrl;
		};

		const updatedPriceFilterRouteParam = function(priceRange) {
			var priceFilter = '';

			if (priceRange.length > 1) {
				var priceMin = priceRange[0];
				var priceMax = priceRange[1];
				priceFilter = '/p/' + (priceMin * 100).toFixed() + '-' + (priceMax * 100).toFixed();
			}

			return priceFilter;
		};

		const updatedColorFilterRouteParam = function(colors) {
			var colorFilters = '';
			if (colors.length) {
				colorFilters = '/c/' + colors.join('-');
			}
			return colorFilters;
		};

		const updatedRatingFilterRouteParam = function(ratings) {
			var ratingFilters = '';
			if (ratings.length) {
				ratingFilters = '/r/' + ratings.join('-');
			}
			return ratingFilters;
		};

		const updatedProdTimeRouteParam = function(prodTimes) {
			var timeFilter = '';
			if (prodTimes.length) {
				timeFilter = '/s/' + prodTimes.join('-');
			}
			return timeFilter;
		};

		const updatedImprintLocationCountRouteParam = function(imprintLocationCounts) {
			var imprintLocationCountFilter = '';
			if (imprintLocationCounts.length) {
				imprintLocationCountFilter = '/l/' + imprintLocationCounts.join('-');
			}
			return imprintLocationCountFilter;
		};

		const updatedImprintColorCountRouteParam = function(imprintColorCounts) {
			var imprintColorCountFilter = '';
			if (imprintColorCounts.length) {
				imprintColorCountFilter = '/m/' + imprintColorCounts.join('-');
			}
			return imprintColorCountFilter;
		};

		const updatedTagFilterRouteParam = function(tags) {
			var tagFilter = '';

			if (tags.length) {
				tagFilter = '/t/' + tags.join('-');
			}
			return tagFilter;
		};

		const updatedFilterQueryString = function(pageNo, sortMap, sort, subSearch, pageSize) {
			var newQueryString = '?';

			if (advancedSearchUrl && advancedSearchUrl.value) {
				newQueryString += advancedSearchUrl.value + '&';
			}

			if (pageNo && parseInt(pageNo) !== 1) {
				newQueryString += 'page=' + pageNo + '&';
			}

			if (pageSize && parseInt(pageSize) !== filterValues.GetDefaultPageSize()) {
				newQueryString += 'PS=' + pageSize + '&';
			}

			if (sort && sort !== filterValues.GetDefaultSort()) {
				for (var i = 0; i < sortMap.length; i++) {
					if (sortMap[i].v.search(new RegExp(sort, 'i')) > -1) {
						newQueryString += sortMap[i].q + '&';
						break;
					}
				}
			}

			if (subSearch) {
				newQueryString += 'ss=' + subSearch + '&';
			}

			return newQueryString.substr(0, newQueryString.length - 1);
		};

		const updatePageSize = function(pageSize) {
			const currentUrl = window.location.href;
			const pageSizeQueryString = 'PS=' + pageSize;
			const newUrl = currentUrl + (currentUrl.indexOf('?') > -1 ? '&' : '?') + pageSizeQueryString;

			history.replaceState({ title: document.getElementById('BaseTitle').value, keywords: document.getElementById('BaseKeywords').value, description: document.getElementById('BaseDescription').value }, '', newUrl);
		};

		const urlPath = function() {
			const currentUrl = window.location.href;
			var baseUrl = '';

			if (document.getElementById('FilterFriendlyUrl').value.length > 0) {
				baseUrl = document.getElementById('FilterFriendlyUrl').value;
			} else {
				baseUrl = getBaseUrl(currentUrl);
			}
			const urlArray = currentUrl.split('/');
			const prot = urlArray[0];
			const host = urlArray[2];
			const url = prot + '//' + host;

			return baseUrl.replace(url, '');
		};

		return {
			Update: update,
			UpdatePageSize: updatePageSize,
		};
	})();

	const initializeState = function() {
		const loc = window.location.href;
		const title = document.title;
		//const keywords = document.querySelector('meta[name="keywords"]').getAttribute('content');
		const description = document.querySelector('meta[name="description"]').getAttribute('content');
		//, keywords: keywords
		history.replaceState({ title: title, description: description }, '', loc);

		//polyfill for closest in IE
		if (window.Element && !Element.prototype.closest) {
			Element.prototype.closest = function (s) {
				var matches = (this.document || this.ownerDocument).querySelectorAll(s),
					i,
					el = this;
				do {
					i = matches.length;
					while (--i >= 0 && matches.item(i) !== el) { };
				} while ((i < 0) && (el = el.parentElement));
				return el;
			};
		}
		
		if(document.getElementById('PriceRange') !== null){
			priceRangeFilter.Init();
		}
		
		subSearchFilter.Events();
		selectedFilters.Events();
		filterMenu.Events();
		colorSelectorFilter.Events();
		eventListners.PagerEvent();
		//eventListners.BetterChoicesEvent();
		radioButtonFilter.Events();
		updatePageFromUrl.Update();
		filterAccordions.init(document.getElementById("mainContent"));
		logPosition.Init();
	};

	const subSearchFilter = (function() {
		const searchText = document.getElementById('txtInnerSearch');
		const submit = document.getElementById('submitInnerSearch');

		const events = function() {
			submit.addEventListener('click', function(e) {
				e.preventDefault();
				var subSearch = searchText.value;

				if (subSearch.length === 0) {
					clear();
					updateFilterUrl.Update(filterValues, prodListValues, false);
				} else {
					if (checkForTagName(subSearch)) {
						add(true);
						updateFilterUrl.Update(filterValues, prodListValues, false);
					} else {
						searchText.value = '';
					}
				}
			});

			searchText.addEventListener('keydown', function(e) {
				if (e.keyCode === 13) {
					e.preventDefault();
					submit.click();
				}
			});
		};

		const checkForTagName = function(term) {
			filterMenu.AddTagFilters();

			return Array.prototype.every.call(filterMenu.GetMenuContainer.querySelectorAll('input[data-filter], button[data-filter]'), function(element) {
				if (element.getAttribute('data-filter').toLowerCase() === term.toLowerCase()) {
					element.click();
					return false;
				}
				return true;
			});
		};

		const add = function(logFilter) {
			var filterName = document.getElementById('SubSearchFilterTitle').value.replace('##search##', searchText.value);

			selectedFilters.RemoveFromSelectedFilter(searchText);
			filterValues.AddSubSearchFilter(searchText.id, searchText.value, logFilter);
			searchText.value = '';
			selectedFilters.AddToSelectedFilter(searchText, filterName);
		};

		const update = function(term) {
			searchText.value = term;
			add(true);
		};

		const clear = function() {
			searchText.value = '';
			filterValues.RemoveSubSearchFilter();
			selectedFilters.RemoveFromSelectedFilter(searchText);
		};

		return {
			Clear: clear,
			Update: update,
			Add: add,
			Events: events,
		};
	})();

	const priceRangeFilter = (function() {
		var priceMinimum,
		priceMaximum,
		priceRange,
		slider,
		priceMin,
		priceMax,
		apply,
		sliderStep;
		
		const initVars = function(){
			priceMinimum = parseFloat(document.getElementById('PriceMinOriginal').value);
			priceMaximum = parseFloat(document.getElementById('PriceMaxOriginal').value);
			priceRange = document.getElementById('PriceRange');
			slider = document.getElementById('price-range');
			priceMin = document.getElementById('PriceMin');
			priceMax = document.getElementById('PriceMax');
			apply = document.getElementById('filterPriceApply');
			sliderStep = .01;
		};
		const init = function() {
			initVars();
			
			// throws an error with ui slider when the values are the same
			if(priceMinimum !== priceMaximum){
				setupPriceSlider();
			}
			parsePrice();
			events();
		};

		const setupPriceSlider = function() {
			noUiSlider.create(slider, {
				start: [priceMinimum, priceMaximum],
				connect: true,
				animate: true,
				range: {
					'min': priceMinimum,
					'max': priceMaximum,
				},
				step: sliderStep,
			});

			if (slider) {
				var sliderValues = slider.noUiSlider.get();
				priceMin.value = Number.parseFloat(sliderValues[0]).toFixed(2);
				priceMax.value = Number.parseFloat(sliderValues[1]).toFixed(2);
			}
		};

		const events = function() {
			if (slider.noUiSlider) {
				slider.noUiSlider.on('update', function(values, handle) {
					if (handle) {
						priceMax.value = values[handle];
					} else {
						priceMin.value = values[handle];
					}
				});
			}
			priceMin.addEventListener('blur', function() {
				const min = parseFloat(priceMin.value);

				if (!isNaN(min)) {
					var max = priceMax.value;

					if (min < priceMinimum) {
						priceMin.value = priceMinimum;
					}
					if (min > parseFloat(max)) {
						priceMin.value = max;
					}
					if (slider.noUiSlider) {
						slider.noUiSlider.set([priceMin.value, null]);
					}
				} else {
					priceMin.value = priceMinimum;
				}
			});
			priceMax.addEventListener('blur', function() {
				const max = parseFloat(priceMax.value);
				if (!isNaN(max)) {
					const min = priceMin.value;

					if (max > priceMaximum) {
						priceMax.value = priceMaximum;
					}

					if (parseFloat(min) > max) {
						priceMax.value = min;
					}
					if (slider.noUiSlider) {
						slider.noUiSlider.set([null, priceMax.value]);
					}
				} else {
					priceMax.value = priceMaximum;
				}
			});
			apply.addEventListener('click', function(e) {
				e.preventDefault();

				var min = parseFloat(priceMin.value);
				var max = parseFloat(priceMax.value);

				// validating user input
				if (isNaN(min) || isNaN(max) || min > max) {
					priceMin.value = priceMinimum;
					priceMax.value = priceMaximum;
					return;
				}

				add(true);

				if (mobileFilterDropDown.classList.contains('active')) {
					mobileFilterDropDown.click();
				}
			});
		};

		const parsePrice = function() {
			priceMin.value = priceMinimum.toFixed(2);
			priceMax.value = priceMaximum.toFixed(2);
		};

		const add = function(logFilter) {
			const filterName = document.getElementById('PriceFilterTitle').value.replace('##PRICELOW##', priceMin.value).replace('##PRICEHIGH##', priceMax.value);

			filterValues.RemovepriceRangeFilter();
			selectedFilters.RemoveFromSelectedFilter(priceRange);
			filterValues.AddpriceRangeFilter(apply.id, priceMin.value, priceMax.value, logFilter);
			selectedFilters.AddToSelectedFilter(priceRange, filterName);

			// should be false if url already contains filter
			if (logFilter) {
				updateFilterUrl.Update(filterValues, prodListValues, false);
			}
		};

		const update = function(min, max, addFilter) {
			if (typeof slider !== 'undefined' && slider && (min != max)) {
				slider.noUiSlider.set([min.toFixed(2), max.toFixed(2)]);
			}
			
			addFilter = addFilter || addFilter === undefined;

			if (addFilter) {
				add(false);
			}
		};

		const clear = function() {
			filterValues.RemovepriceRangeFilter();
			selectedFilters.RemoveFromSelectedFilter(priceRange);
			if (typeof slider !== 'undefined' && slider && (priceMinimum != priceMaximum)) {
				slider.noUiSlider.set([priceMinimum.toFixed(2), priceMaximum.toFixed(2)]);
			}
		};

		return {
			Init: init,
			Add: add,
			Clear: clear,
			Update: update,
		};
	})();

	const colorSelectorFilter = (function() {
		const colorFilter = document.getElementById('colorFilter');

		const action = function(e) {
			e.preventDefault();

			var element = e.currentTarget;

			if (element.getAttribute('aria-selected') !== 'true') {
				add(element, true);
			} else {
				remove(element);
			}

			if (mobileFilterDropDown.classList.contains('active')) {
				mobileFilterDropDown.click();
			}

			updateFilterUrl.Update(filterValues, prodListValues, false);
		};

		const keyAction = function(e) {
			if (e.which === 13) {
				action(e);
			}
		};

		const update = function(element) {
			add(element, false);
		};

		const updateCounts = function(filterOptions) {
			if(document.getElementById('colorFilter')){
				const filters = colorFilter.getElementsByClassName('filterColorBlock');
				let filterId, optionCnt;
	
				for (let i = 0; i < filters.length; i++) {
					filterId = filterValues.GetFilterId(filters[i].id);
					optionCnt = 0;
	
					if (filterId && filterOptions) {
						for (let j = 0; j < filterOptions.length; j++) {
							if (filterOptions[j].Id === filterId) {
								optionCnt = filterOptions[j].Count;
								break;
							}
						}
					}
	
					common.GetUpdatedFilterCount(document.getElementById(filters[i].id + '_label'), optionCnt);
					filters[i].title = filters[i].getAttribute('data-filter') + ' (' + optionCnt + ')';
				}
			}
		};

		const add = function(element, logFilter) {
			element.setAttribute('aria-selected', 'true');
			element.querySelector('.svgWrapper').classList.remove('hide');
			element.querySelector('.svgWrapper').classList.add('show');
			filterValues.AddFilter(element.id, logFilter);
			selectedFilters.AddToSelectedFilter(element);
		};

		const remove = function(element) {
			element.setAttribute('aria-selected', 'false');
			element.querySelector('.svgWrapper').classList.remove('show');
			element.querySelector('.svgWrapper').classList.add('hide');
			filterValues.RemoveFilter(element.id);
			selectedFilters.RemoveFromSelectedFilter(element);
		};

		const clear = function(list) {
			for (var i = 0; i < list.length; i++) {
				remove(document.getElementById(list[i]));
			}
		};

		const events = function() {
			if (colorFilter) {
				Array.prototype.forEach.call(colorFilter.querySelectorAll('button.filterColorBlock'), function(element) {
					element.removeEventListener('click', action);
					element.addEventListener('click', action);
					element.removeEventListener('keydown', keyAction);
					element.addEventListener('keydown', keyAction);
				});
			}
		};

		return {
			Update: update,
			UpdateCounts: updateCounts,
			Events: events,
			Clear: clear,
			Remove: remove,
		};
	})();

	const radioButtonFilter = (function() {
		const timerInterval = 500;
		const productionTime = document.getElementById('productionTimeContent');
		const imprintLocationCount = document.getElementById('imprintLocationCountContent');
		const imprintColorCount = document.getElementById('imprintColorCountContent');
		var productionTimer;
		var locationTimer;
		var colorTimer;

		const action = function(element) {
			// clear out any existing filters previously selected
			const id = element.id.slice(0, element.id.lastIndexOf('_') + 1);
			Array.prototype.forEach.call(selectedFilters.SelectedFilterList.querySelectorAll('div[class*="' + id + '"]'), function(el) {
				var index = el.classList.value.indexOf(id) + id.length;
				filterMenu.MarkUnSelected(document.getElementById(id + el.classList.value.slice(index, index + 1)));
			});

			filterMenu.MarkSelected(element);

			if (mobileFilterDropDown.classList.contains('active')) {
				mobileFilterDropDown.click();
			}
			updateFilterUrl.Update(filterValues, prodListValues, false);
		};

		const events = function() {
			productionTime && Array.prototype.forEach.call(productionTime.querySelectorAll('input[type=radio]'), function(el) {
				el.addEventListener('change', function() {
					clearTimeout(productionTimer);
					productionTimer = setTimeout(action, timerInterval, this);
				});
			});

			imprintLocationCount && Array.prototype.forEach.call(imprintLocationCount.querySelectorAll('input[type=radio]'), function(el) {
				el.addEventListener('change', function() {
					clearTimeout(locationTimer);
					locationTimer = setTimeout(action, timerInterval, this);
				});
			});

			imprintColorCount && Array.prototype.forEach.call(imprintColorCount.querySelectorAll('input[type=radio]'), function(el) {
				el.addEventListener('change', function() {
					clearTimeout(colorTimer);
					colorTimer = setTimeout(action, timerInterval, this);
				});
			});
		};

		return {
			Events: events,
		};
	})();

	// set values from RouteData and QueryString
	const updatePageFromUrl = (function() {
		var currentUrl;

		const update = function() {
			filterMenu.UnSelectAllFilters(false);

			currentUrl = window.location.href;

			var filterCnt = setColorFilter() +
				setRatingFilter() +
				setProductionTimeFilter() +
				setImprintLocationCountFilter() +
				setImprintColorCountFilter() +
				setTagFilter() +
				setpriceRangeFilter() +
				setSubSearch();

			setPageNumber();
			setSortOrder();
			setPageSize();

			// If there are no filters applied make sure that the clear all filters option is not visible.
			if (filterCnt === 0) {
				selectedFilters.RemoveAllFromSelectedFilter();
			}
		};

		const setpriceRangeFilter = function() {
			var matches = prodListValues.PricePattern.exec(currentUrl);
			var amounts = [];

			if (matches) {
				var vals = matches[0].split('/');
				if (vals.length >= 3) {
					amounts = amounts.concat(vals[2].split('-'));

					if (isNaN(parseFloat(amounts[0])) || isNaN(parseFloat(amounts[1]))) {
						return 0;
					}

					var priceMin = (amounts[0] / 100);
					var priceMax = (amounts[1] / 100);

					priceRangeFilter.Update(priceMin, priceMax);
				}
			}

			return filterValues.GetPriceFilter().length;
		};

		const setColorFilter = function() {
			/* set color filters from url */
			var matches = prodListValues.ColorPattern.exec(currentUrl);
			var ids = [];
			var startLen = filterValues.GetColorFilter().length;

			if (matches) {
				var vals = matches[0].split('/');

				if (vals.length >= 3) {
					ids = ids.concat(vals[2].split('-'));

					filterMenu.MarkSelectedReload(filterValues.GetColorFilter(), ids, filterValues.getColorElementId);
				}
			}

			filterMenu.MarkUnSelectedReload(filterValues.GetColorFilter(), ids, filterValues.getColorElementId, startLen);

			return filterValues.GetColorFilter().length;
		};

		const setRatingFilter = function() {
			/* set rating filter from url */
			var matches = prodListValues.RatingPattern.exec(currentUrl);
			var ids = [];
			var startLen = filterValues.GetRatingFilter().length;

			if (matches !== null) {
				var vals = matches[0].split('/');

				if (vals.length >= 3) {
					ids = ids.concat(vals[2].split('-'));

					filterMenu.MarkSelectedReload(filterValues.GetRatingFilter(), ids, filterValues.GetRatingElementId);
				}
			}
			filterMenu.MarkUnSelectedReload(filterValues.GetRatingFilter(), ids, filterValues.GetRatingElementId, startLen);

			return filterValues.GetRatingFilter().length;
		};

		const setProductionTimeFilter = function() {
			/* set production time filter from url */
			var matches = prodListValues.ProdTimePattern.exec(currentUrl);
			var ids = [];
			var startLen = filterValues.GetProdTimeFilter().length;

			if (matches !== null) {
				var vals = matches[0].split('/');

				if (vals.length >= 3) {
					ids = ids.concat(vals[2].split('-'));

					filterMenu.MarkSelectedReload(filterValues.GetProdTimeFilter(), ids, filterValues.GetProductionTimeElementId);
				}
			}
			filterMenu.MarkUnSelectedReload(filterValues.GetProdTimeFilter(), ids, filterValues.GetProductionTimeElementId, startLen);

			return filterValues.GetProdTimeFilter().length;
		};

		const setImprintLocationCountFilter = function() {
			/* set imprint location filter from url */
			var matches = prodListValues.ImprintLocationCountPattern.exec(currentUrl);
			var ids = [];
			var startLen = filterValues.GetImprintLocationCountFilter().length;

			if (matches !== null) {
				var vals = matches[0].split('/');

				if (vals.length >= 3) {
					ids = ids.concat(vals[2].split('-'));

					filterMenu.MarkSelectedReload(filterValues.GetImprintLocationCountFilter(), ids, filterValues.GetImprintLocationCountElementId);
				}
			}
			filterMenu.MarkUnSelectedReload(filterValues.GetImprintLocationCountFilter(), ids, filterValues.GetImprintLocationCountElementId, startLen);

			return filterValues.GetImprintLocationCountFilter().length;
		};

		const setImprintColorCountFilter = function() {
			/* set imprint color filter from url */
			var matches = prodListValues.ImprintColorCountPattern.exec(currentUrl);
			var ids = [];
			var startLen = filterValues.GetImprintColorCountFilter().length;

			if (matches !== null) {
				var vals = matches[0].split('/');

				if (vals.length >= 3) {
					ids = ids.concat(vals[2].split('-'));

					filterMenu.MarkSelectedReload(filterValues.GetImprintColorCountFilter(), ids, filterValues.GetImprintColorCountElementId);
				}
			}
			filterMenu.MarkUnSelectedReload(filterValues.GetImprintColorCountFilter(), ids, filterValues.GetImprintColorCountElementId, startLen);

			return filterValues.GetImprintColorCountFilter().length;
		};

		const setPageNumber = function() {
			/* set page number from url */
			var matches = prodListValues.PagePattern.exec(currentUrl);
			if (matches !== null) {
				var vals = matches[0].split('=');

				if (vals.length >= 2) {
					filterValues.AddPageNumber(vals[1]);
				}
			}
		};

		const setPageSize = function() {
			/* set page number from url */
			const matches = prodListValues.ItemsPerPagePattern.exec(currentUrl);
			var page = filterValues.GetDefaultPageSize();

			if (matches !== null) {
				var vals = matches[0].split('=');

				if (vals.length >= 2) {
					page = vals[1];
				}
			} else {
				const element = document.getElementById('itemsPerPageDropDown');

				if (element && parseInt(element.value) !== page) {
					page = parseInt(element.value);
					updateFilterUrl.UpdatePageSize(page);
				}
			}

			filterMenu.SetPageSize(page);
		};

		const setSubSearch = function() {
			var matches = prodListValues.SubSearchPattern.exec(currentUrl);
			subSearchFilter.Clear();
			if (matches !== null) {
				var vals = matches[0].split('=');

				if (vals.length >= 2) {
					var term = unescape(vals[1]);
					subSearchFilter.Update(term);
					return 1;
				}
			}

			return 0;
		};

		const setSortOrder = function() {
			/* set filter order from url*/
			const matches = prodListValues.SortByPattern.exec(currentUrl);
			var sort = filterValues.GetDefaultSort();

			if (matches) {
				for (var i = 0; i < prodListValues.SortMap.length; i++) {
					if (prodListValues.SortMap[i].q.toLowerCase() === matches[0].toLowerCase()) {
						sort = prodListValues.SortMap[i].v.replace('string:', '');
						break;
					}
				}
			}

			filterMenu.SetFilterOrder(filterValues.GetSortElementId(sort), false);
		};

		const setTagFilter = function() {
			/* set tag filters from url */
			var matches = prodListValues.TagPattern.exec(currentUrl);
			var ids = [];
			var startLen = filterValues.GetTagFilter().length;
			var defaultTag = checkCustomUrl();

			if (matches) {
				var vals = matches[0].split('/');

				if (vals.length >= 3) {
					ids = ids.concat(vals[2].split('-'));
				}
			}

			if (defaultTag.length) {
				ids = ids.concat(defaultTag.split('-'));
			}

			if (ids.length) {
				filterMenu.AddTagFilters();
			}

			filterMenu.MarkSelectedReload(filterValues.GetTagFilter(), ids, filterValues.GetTagElementId);
			filterMenu.MarkUnSelectedReload(filterValues.GetTagFilter(), ids, filterValues.GetTagElementId, startLen);

			return filterValues.GetTagFilter().length;
		};

		const checkCustomUrl = function() {
			/* set values for rewriten urls (i.e. 'athletic-tshirts' is 'tag/97/t/4531' */
			var defaultTag = document.getElementById('rewriteDefaultTag').value;
			var urlVals = currentUrl.split('/');
			var urlVals2 = urlVals[3].split('?');
			if (document.getElementById('filterRewrite').value === '') {
				if (defaultTag !== '' && currentUrl.indexOf(document.getElementById('FilterFriendlyUrl').value) === -1) {
					document.getElementById('filterRewrite').value = urlVals2[0];
				} else {
					defaultTag = '';
					document.getElementById('rewriteDefaultTag').value = '';
				}
			} else if (urlVals2[0] !== document.getElementById('filterRewrite').value) {
				defaultTag = '';
			}

			return defaultTag;
		};

		return {
			Update: update,
		};
	})();

	const logPosition = (function() {

		const logPosition = function(e) {
			e.preventDefault();
			if (typeof s !== 'undefined') {
				const pos = this.getAttribute('data-position');
				s.eVar38 = pos;
				s.prop38 = pos;
				try {
					s.t();
				}
				catch {
				}
			}

			gtm(this, e);
		};

		const setOnClick = function() {
			var products = [];
			Array.prototype.forEach.call(document.querySelectorAll('a.itemImage'), function(el) {
				el.removeEventListener('click', logPosition);
				el.addEventListener('click', logPosition);
				products.push(getImpressionObj(el));
			});
			gtmImpressions(products);
		}

		const init = function() {
			var products = [];
			Array.prototype.forEach.call(document.querySelectorAll('a.itemImage'), function(el) {
				el.addEventListener('click', logPosition);
				products.push(getImpressionObj(el));
			});
			gtmImpressions(products);
		}

		/**
		 * Call this function when a user clicks on a product link. This function uses the event
		 * callback datalayer variable to handle navigation after the ecommerce data has been sent
		 * to Google Analytics.
		 * @param {Object} productObj An object representing a product.
		 */
		const gtm = function(productObj, e) {
			if (typeof dataLayer !== 'undefined' && window.ga) {
				dataLayer.push({
					'event': 'productClick',
					'ecommerce': {
						'click': {
							'actionField': { 'list': 'Search Results' },      // Optional list property.
							'products': [{
								'name': productObj.getAttribute('data-name'),
								'id': productObj.getAttribute('data-alias'),
								'price': productObj.getAttribute('data-price'),
								'brand': productObj.getAttribute('data-brand'),
								'category': productObj.getAttribute('data-category'),
								'position': productObj.getAttribute('data-position')
							}]
						}
					},
					'eventCallback': function() {
						e.ctrlKey ? window.open(productObj.href, '_blank') : window.location.href = productObj.href;
					}
				});
			} else {
				e.ctrlKey ? window.open(productObj.href, '_blank') : window.location.href = productObj.href;
			}
		}

		const getImpressionObj = function(element) {
			return {
				'name': element.getAttribute('data-name'),
				'id': element.getAttribute('data-alias'),
				'price': element.getAttribute('data-price'),
				'brand': element.getAttribute('data-brand'),
				'category': element.getAttribute('data-category'),
				'list': 'Search Results',
				'position': element.getAttribute('data-position')
			};
		}

		const gtmImpressions = function(products) {
			if (products.length && typeof dataLayer !== 'undefined' && window.ga) {
				const code = document.getElementById('gtmCurrencyCode');

				// Measures product impressions and also tracks a standard
				// pageview for the tag configuration.
				// Product impressions are sent by pushing an impressions object
				// containing one or more impressionFieldObjects.
				dataLayer.push({
					'event': 'productListView',
					'ecommerce': {
						'currencyCode': code ?  code.value : 'USD',
						'impressions': products
					}
				});
			}
		}

		return {
			SetOnClick: setOnClick,
			Init: init
		};

	})();

	// page ajax calls
	const dataService = (function() {
		const verificationToken = document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value;
		
		const getProducts = function(path, updateUrl, newPathToUse) {
			const loading = document.getElementById('loadingItems');
			const loadingHTML = '<div id="listLoading" class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';

			loading.classList.remove('hide');
			document.getElementById("loadingItems").querySelector(".spinnerCtrPg").innerHTML = loadingHTML; // insert HTML so screen reader announces it
			//productContainer.classList.add('transparent');
			// scrolling to top is disorienting for a11y users
			//document.getElementById('mainContent').scrollIntoView({ behavior: 'smooth' });
				 
			fetch('/list/v2/filter' + path, { 
				method: 'POST', 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', 'RequestVerificationToken': verificationToken, '__RequestVerificationToken': verificationToken }
			}).then(function(response){
				return  (response.ok) ? response.json() : []; // response.json() returns a promise
			}).then(function(response){
				// Update the filter menu with new filters.
				filterMenu.UpdateMenuSelections(response.ProductFilter); 
				document.getElementById('productCount').textContent = response.ItemCountLabel;

				// Update with the new filter results.
				productContainer.innerHTML = response.ProductView;
				productContainer.insertAdjacentHTML("afterbegin", '<div id="loadingItems" class="hide"><div class="spinnerOverlay"></div><div class="spinnerCtrPg"></div></div>');
				logPosition.SetOnClick();
				// pager may not always be present if only one page of products exist
				Array.prototype.forEach.call(document.getElementsByClassName('pagingWrapper'), function(element) {
					element.innerHTML = response.PagerView;

					// fix up for bottom pager
					response.PagerView = response.PagerView.substr(response.PagerView.indexOf('</p>') + 4).replace(' - Top', ' - Bottom');
				});

				priceRangeFilter.Update(response.MinPrice, response.MaxPrice, false);

				// update structured data
				const structureData = document.getElementById('structureddata');
				if (structureData) {
					structureData.innerHTML = response.StructuredData;
				}
				
				if (document.getElementById('videoBundle')) {
					videoJS4i.BindNewLinks(); //videoJS4i is defined in videodialog js file
				}
				
				if (document.getElementById('favoritesBundle')) {
					const favHelper = new favoriteHelper();
					favHelper.init(); 
				}
				if (typeof overrideRankHelper !== 'undefined' && overrideRankHelper){
					(new overrideRankHelper).init();
				}

				loading.classList.add('hide');
				if (document.getElementById("listLoading")) {
					document.getElementById("listLoading").parentNode.removeChild(document.getElementById("listLoading"));
				}
				//productContainer.classList.remove('transparent');
				eventListners.PagerEvent();
				/*let bcButtons = [].slice.call(document.querySelectorAll('.betterChoicesButton'));
				if(bcButtons.length > 0) betterChoicesModal.BindTriggerElements(bcButtons);*/
				
				starRatingJS.Init();
				
				//focus management - first product
				setTimeout(function(){
					const item = document.querySelector(".productList").querySelector("a");
					if (item) {
						item.focus();
					}
				}, 350);

				if (updateUrl === true) {
					prodListValues.ReplaceCurrentUrl(newPathToUse, document.getElementById('BaseTitle').value, document.getElementById('BaseDescription').value, document.getElementById('BaseKeywords').value);
				}
			}).catch(function(error){
				console.log(error);

				// try reloading page with new filter path
				window.location.href = path;
			});

			return;
		};

		const filterLog = function(filterId, filtervalue) {
			// check if we need to wait until js finishes parsing 
			if (typeof ajax === 'function') {
				saveFilter(filterId, filtervalue);
			} else {
				setTimeout(function() { saveFilter(filterId, filtervalue); }, 2000);
			}
		};

		const saveFilter = function(filterId, filtervalue) {
			const account = typeof s_account === 'string' ? s_account : '4imprintprod';
            		const s = typeof s_gi === 'function' ? s_gi(account) : "";
            		
			var filtertype = filterId = "MoreFiltersButton" ? filterId : document.getElementById(filterId).getAttribute('data-group');
			var pagetype = '';
			var pagevalue = '';

			// Log in Omniture.
			if (s !== "") {
				if (filtertype !== 'SubSearch') {
					s.prop18 = filtertype + ' : ' + filtervalue; // List Filter prop
					s.prop19 = filtertype; // List Filter Group prop
					s.eVar30 = s.prop18;
					s.eVar32 = s.prop18;
					s.prop22 = '';
				} else {
					s.prop18 = '';
					s.prop19 = '';
					s.eVar30 = '';
					s.eVar32 = '';
					s.prop22 = filtervalue;
				}

				pagetype = s.prop12;
				if (s.prop12 === 'Search') {
					pagevalue = s.prop1; // internal search prop
				} else {
					pagevalue = s.prop7; // groupname, taggroupname, or storename
				}

				s.t();
			}

			if (filtertype === null) filtertype = '';
			if (typeof filtervalue === 'undefined') filtervalue = '';

			const logFilterModel = {
				PageType: pagetype,
				PageValue: pagevalue,
				FilterType: filtertype,
				FilterValue: filtervalue,
			};
	        			
			fetch('/filters/v2/logfilter', { 
				method: 'POST', 
				body: JSON.stringify(logFilterModel),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', 'RequestVerificationToken': verificationToken, '__RequestVerificationToken': verificationToken }
			}).then(function(response){
				return  (response.ok) ? response.text() : []; 
			}).then(function(response){
				// proceed after successful request
			}).catch(function(error){
				// place error handling here
			});
		};
		
		/*const betterChoicesDialog = function (productId) {	        			
			fetch('/productdata/taggeddescription', { 
				method: 'POST', 
				body: JSON.stringify({ productId : productId }), 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', '__RequestVerificationToken': verificationToken }
			}).then(function(response){
				return  (response.ok) ? response.json() : []; // response.json() returns a promise
			}).then(function(response){
				document.querySelector(".betterChoicesContent").querySelector(".dialogContent").innerHTML = response.html;
			}).catch(function(error){
				document.querySelector(".betterChoicesContent").querySelector(".dialogContent").innerHTML = "<p class='text16'>Uh oh! We're having issues loading this information right now. Please try again later.</p>";
			});
		};*/

		return {
			GetProducts: getProducts,
			FilterLog: filterLog
			//BetterChoicesDialog: betterChoicesDialog,
		};
	})();

	return {
		SelectedFilters: selectedFilters,
		InitializeState: initializeState,
	};
}());

productList4i.InitializeState();